import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NboEmobilityRequest } from '@api-cc';
import { IInsulation } from '@theia-cc/ht/core';
import { IAppStateHt, ICollectedDataHt } from '@theia-cc/ht/state';
import {
  caseAgnosticQueryParam,
  cleanEmptyProperties,
  getRestQueryParams,
  getUserAddressFromQueryParams,
  parseFormString,
} from '@theia-cc/shared/helpers';
import { IBuildingSummary, IUserAddressParams, IUserInfoParams } from '@theia-cc/shared/models';
import {
  ISplitQueryParamsResultBase,
  ShareLinkService,
  USER_INFO_FIELDS,
} from '@theia-cc/shared/services';

export type ShareLinkQueryParams = Partial<ICollectedDataHt> &
  Partial<IUserInfoParams> &
  Partial<IUserAddressParams> &
  Partial<IInsulation> &
  Partial<IBuildingSummary> & { Language?: string; partnerId?: string };

export interface ISplitQueryParamsResultHt extends ISplitQueryParamsResultBase<ICollectedDataHt> {
  selectedTemplateIdHt: string;
  chargingPower: NboEmobilityRequest.ChargingPowerEnum;
}

@Injectable()
export class ShareLinkServiceHt extends ShareLinkService {
  constructor(
    private store: NgRedux<IAppStateHt>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  urlLinkQueryParamsSelector(state: IAppStateHt): any {
    const {
      currentLanguage: Language,
      config: {
        queryParams: { partnerId },
      },
      collectedData: { nboLeadIdHt, variantIdHt },
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      partnerId,
      Language,
      nboLeadIdHt,
      variantIdHt,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  shareLinkQueryParamsSelector(state: IAppStateHt): ShareLinkQueryParams {
    const {
      collectedData: {
        buildingAge,
        buildingData: {
          aboveSea,
          buildingGroundArea,
          buildingLevels,
          buildingRoofArea,
          buildingRoofOverhangArea,
          buildingRoofShape,
          buildingVolume,
          buildingWallArea,
        },
        dismantleTank,
        familyMemberCount,
        heatingArea,
        heatingEmission,
        heatingType,
        heatPumpType,
        insulation: { WINDOWS, FACADE, ROOF, BASEMENT },
        nboLeadIdHt,
        variantIdHt,
        nboSelectedTemplateIdHt,
        powerConsumptionCalculated,
        powerConsumptionSelected,
        smartEnergy,
        responsibleConstructionWork,
        responsibleElectricalConnection,
        geothermalProbe,
        userAddress: { lat, lon, place: City, street: Street, zip: ZipCode },
        user: {
          email: Email,
          firstName: FirstName,
          lastName: LastName,
          telephone: PhoneNumber,
          title: Title,
          companyName: CompanyName,
        },
        largeSystem,
        heatingPower,
      },
      config: {
        queryParams: { partnerId },
      },
      currentLanguage: Language,
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      buildingAge,
      dismantleTank,
      familyMemberCount,
      heatingArea,
      heatingEmission,
      heatingType,
      heatPumpType,
      nboSelectedTemplateIdHt,
      nboLeadIdHt,
      variantIdHt,
      powerConsumptionCalculated,
      powerConsumptionSelected,
      smartEnergy,
      responsibleConstructionWork,
      responsibleElectricalConnection,
      geothermalProbe,

      partnerId,
      Language,

      aboveSea,
      buildingGroundArea,
      buildingLevels,
      buildingRoofArea,
      buildingRoofOverhangArea,
      buildingRoofShape,
      buildingVolume,
      buildingWallArea,

      lat,
      lon,
      City,
      Street,
      ZipCode,

      Email,
      FirstName,
      LastName,
      PhoneNumber,
      Title,
      CompanyName,

      WINDOWS,
      FACADE,
      ROOF,
      BASEMENT,
      largeSystem,
      heatingPower,

      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  splitQueryParams<
    T = {
      collectedData: Partial<ICollectedDataHt>;
      partnerId: string;
      Language: string;
      restQueryParams: Partial<ShareLinkQueryParams>;
    }
  >(queryParams: ShareLinkQueryParams): T {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams as any);

    // tslint:disable:object-shorthand-properties-first
    const collectedData: Partial<ICollectedDataHt> = cleanEmptyProperties({
      buildingAge: getCaseAgnosticQueryParam('buildingAge'),
      dismantleTank: getCaseAgnosticQueryParam('dismantleTank'),
      familyMemberCount: parseFormString.number(getCaseAgnosticQueryParam('familyMemberCount')),
      heatingArea: parseFormString.number(getCaseAgnosticQueryParam('heatingArea')),
      heatingEmission: getCaseAgnosticQueryParam('heatingEmission'),
      heatingType: getCaseAgnosticQueryParam('heatingType'),
      heatPumpType: getCaseAgnosticQueryParam('heatPumpType'),
      nboLeadIdHt: getCaseAgnosticQueryParam('nboLeadIdHt'),
      variantIdHt: getCaseAgnosticQueryParam('variantIdHt'),
      nboSelectedTemplateIdHt: getCaseAgnosticQueryParam('nboSelectedTemplateIdHt'),
      powerConsumptionCalculated: parseFormString.number(
        getCaseAgnosticQueryParam('powerConsumptionCalculated')
      ),
      powerConsumptionSelected: parseFormString.number(
        getCaseAgnosticQueryParam('powerConsumptionSelected')
      ),
      smartEnergy: getCaseAgnosticQueryParam('smartEnergy'),
      responsibleConstructionWork: getCaseAgnosticQueryParam('responsibleConstructionWork'),
      responsibleElectricalConnection: getCaseAgnosticQueryParam('responsibleElectricalConnection'),
      geothermalProbe: getCaseAgnosticQueryParam('geothermalProbe'),

      buildingData: {
        aboveSea: parseFormString.number(getCaseAgnosticQueryParam('aboveSea')),
        buildingGroundArea: parseFormString.number(getCaseAgnosticQueryParam('buildingGroundArea')),
        buildingLevels: parseFormString.number(getCaseAgnosticQueryParam('buildingLevels')),
        buildingRoofArea: parseFormString.number(getCaseAgnosticQueryParam('buildingRoofArea')),
        buildingRoofOverhangArea: parseFormString.number(
          getCaseAgnosticQueryParam('buildingRoofOverhangArea')
        ),
        buildingRoofShape: parseFormString.number(getCaseAgnosticQueryParam('buildingRoofShape')),
        buildingVolume: parseFormString.number(getCaseAgnosticQueryParam('buildingVolume')),
        buildingWallArea: parseFormString.number(getCaseAgnosticQueryParam('buildingWallArea')),
      },
      userAddress: getUserAddressFromQueryParams(queryParams),
      user: {
        email: getCaseAgnosticQueryParam('Email'),
        firstName: getCaseAgnosticQueryParam('FirstName'),
        lastName: getCaseAgnosticQueryParam('LastName'),
        telephone: getCaseAgnosticQueryParam('PhoneNumber'),
        title: getCaseAgnosticQueryParam('Title'),
        companyName: getCaseAgnosticQueryParam('CompanyName'),
        emailDisclaimer: getCaseAgnosticQueryParam('nboLeadIdHt'),
      },
      insulation: {
        WINDOWS: parseFormString.boolean(getCaseAgnosticQueryParam('WINDOWS')),
        FACADE: parseFormString.boolean(getCaseAgnosticQueryParam('FACADE')),
        ROOF: parseFormString.boolean(getCaseAgnosticQueryParam('ROOF')),
        BASEMENT: parseFormString.boolean(getCaseAgnosticQueryParam('BASEMENT')),
      },
      largeSystem: parseFormString.boolean(getCaseAgnosticQueryParam('largeSystem')),
      leadTag: getCaseAgnosticQueryParam('leadTag'),
      heatingPower: parseFormString.number(getCaseAgnosticQueryParam('heatingPower')),
    });
    // tslint:enable:object-shorthand-properties-first
    return {
      collectedData,
      partnerId: getCaseAgnosticQueryParam('partnerId'),
      Language: getCaseAgnosticQueryParam('Language'),
      restQueryParams: getRestQueryParams(queryParams as any),
    } as unknown as T; // @TODO fix typing
  }
}
